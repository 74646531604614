import React, { useState, useEffect } from 'react';
import { Card,   Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Select, MenuItem } from '@mui/material';
import { createValidations, getValidations, updateValidations, deleteValidations } from "../redux/actions/testcase";
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from "@mui/material/Divider";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import '../styles/project.css';
import '../styles/custom.css';
import '../styles/common.css';
import { VALIDATION_CHOICES } from '../constants/validationType';

const Validation = ({ createValidations, getValidations, updateValidations, deleteValidations, data, validationListData }) => {
    const [newRowXpath, setNewRowXpath] = useState('');
    const [newRowValue, setNewRowValue] = useState('');
    const [newRowSleepTime, setNewRowSleepTime] = useState('');
    const [newRowIsEncrypted, setNewRowIsEncrypted] = useState('');
    const [newRowvalid_type, setNewRowvalid_type] = useState(VALIDATION_CHOICES[0][0]);
    const [validationList, setvalidationList] = useState([]);
    const [error, setError] = useState('');
    const [stepName, setStepName] = useState('');

    useEffect(() => {
        validationListData = null;
      
        if (data.step_id) {
            getValidations(data?.step_id);
            setStepName(data?.step_name);
            
           
        }
    }, [data]
    )
     

    useEffect(() => {
        if (validationListData && validationListData.length > 0) {
            let initialRows = [];
            validationListData?.map((validation, i) => {
                const obj = {
                    valid_id: validation.valid_id,
                    index: i + 1,
                    valid_xpath: validation.valid_xpath,
                    valid_type:validation.valid_type,
                    valid_expectedvalue: validation.valid_expectedvalue,
                }
                initialRows.push(obj);
                setvalidationList(initialRows);
            })
        }
    }, [validationListData])

    const handleAddRow = () => {
        const newRow = {
            id: validationList.length + 1,
            xPath: newRowXpath,
            value: newRowValue,
            valid_type:newRowvalid_type,
            sleepTime: newRowSleepTime,
            isEncrypted: newRowIsEncrypted
        };

        setvalidationList([...validationList, newRow]);
        setNewRowXpath('');
        setNewRowValue('');
        setNewRowSleepTime('');
        setNewRowvalid_type(VALIDATION_CHOICES[0][0]);
        
        setNewRowIsEncrypted(false);
        setError('');
    };
    const handleStatusChange = (event, index) => {
        const updatedValidationList = [...validationList];
        updatedValidationList[index].valid_type = event.target.value;
        setvalidationList(updatedValidationList);
         
    };
    const deleteValidationById = (id, index) => {

        if (!validationList[index].valid_id) {
            const updatedValidationList = [...validationList];
            updatedValidationList.splice(index, 1);
            setvalidationList(updatedValidationList);
        }
        else {
            deleteValidations(id)
                .then(() => {
                    const updatedValidationList = [...validationList];
                    updatedValidationList.splice(index, 1);
                    setvalidationList(updatedValidationList);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    const handleXpathChange = (event, index) => {
        const updatedvalidationList = [...validationList];
        updatedvalidationList[index].valid_xpath = event.target.value;
        setvalidationList(updatedvalidationList);
    }

    const handleValueChange = (event, index) => {
        const updatedvalidationList = [...validationList];
        updatedvalidationList[index].valid_expectedvalue = event.target.value;
        setvalidationList(updatedvalidationList);
    }

   
    const onSubmit = (index) => {
        if (!validationList[index]?.valid_id) {
            const obj = {
                valid_xpath: validationList[index]?.valid_xpath,
                valid_expectedvalue: validationList[index]?.valid_expectedvalue,
                valid_type: validationList[index]?.valid_type,
                test_step_id: data.step_id
            }
            createValidations(obj)
                .then((res) => {
                    validationList[index] = res.data;
                    toast.success("Test validation created successfully");
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
        if (validationList[index]?.valid_id) {
            validationList?.map(data => data.valid_id === validationList[index]?.valid_id)
            const object = {
                id: validationList[index].valid_id,
                valid_xpath: validationList[index]?.valid_xpath,
                valid_expectedvalue: validationList[index]?.valid_expectedvalue,
                valid_type:validationList[index]?.valid_type,
                test_step_id: data.step_id,
            };
            updateValidations(object).then((res) => {
                toast.success("Test validation updated successfully");
            })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>{stepName}</h4>
                <Button onClick={handleAddRow} startIcon={<AddIcon />} style={{ marginLeft: 'auto' }}>
                    Add validation
                </Button>
            </div>
            <br></br>

            <TableContainer component={Card} className='dashboard-table table td'>
                <div className="scroll">
                    <Table>
                        <TableHead className='th'>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>XPath</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Validation Type</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {validationList?.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell >{index + 1}</TableCell>

                                    <TableCell >
                                        <TextField onChange={(event) => handleXpathChange(event, index)}
                                            type="text"
                                            value={row.valid_xpath} />
                                    </TableCell>

                                    <TableCell >
                                        <TextField onChange={(event) => handleValueChange(event, index)}
                                            type="text"
                                            value={row.valid_expectedvalue}
                                        /></TableCell>
                                    <TableCell>
                                        <Select className="form-control"
                                            value={row.valid_type}
                                            onChange={(event) => handleStatusChange(event, index)}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{ height: '40px' }}
                                        >
                                            <MenuItem value={''}>Select type</MenuItem>
                                            {VALIDATION_CHOICES.map((action, index) => (
                                                <MenuItem value={action[0]} key={index}>{action[1]}</MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Save">
                                            <SaveIcon style={{ color: '#34B1AA' }} onClick={() => onSubmit(index)} className="action-icon" />
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <DeleteIcon style={{ color: '#d71b1b' }} onClick={() => deleteValidationById(row.valid_id, index)} className="action-icon clickIconClassDelete" />
                                        </Tooltip>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </TableContainer>
            {error && <span style={{ color: 'red' }}>{error}</span>}
            
           
        </div>
    );
};

const mapStateToProps = (state) => ({
    validationListData: state.auth.validationsList 
     
});

const mapDispatchToProps = {
    createValidations,
    getValidations,
    updateValidations,
    deleteValidations 
};

export default connect(mapStateToProps, mapDispatchToProps)(Validation);
