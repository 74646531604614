import React, { useState } from "react";
import { Paper, Grid, Button, Select, MenuItem, Typography, FormControl, InputLabel } from "@mui/material";

const authorizationTypes = [
  "No Auth",
  "API Key",
  "Bearer Token (OAuth 2.0)",
  "Basic Authentication",
  "Digest Authentication",
  "OAuth 1.0a",
  "OAuth 2.0",
  "JWT (JSON Web Token)",
  "Session-Based Authentication",
  "SAML",
  "OpenID Connect (OIDC)",
  "Hawk Authentication",
  "AWS Signature",
  "Mutual TLS (mTLS)",
  "Custom Authentication",
];

const AuthorizationDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedAuth, setSelectedAuth] = useState("");

  return (
    <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button variant="contained" onClick={() => setShowDropdown(!showDropdown)}>
            Authorization
          </Button>
        </Grid>
      </Grid>

      {showDropdown && (
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel>Select Authorization Type</InputLabel>
          <Select
            value={selectedAuth}
            onChange={(e) => setSelectedAuth(e.target.value)}
          >
            {authorizationTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {selectedAuth && (
        <Typography variant="h6" style={{ marginTop: 20 }}>
          Selected: {selectedAuth}
        </Typography>
      )}
    </Paper>
  );
};

export default AuthorizationDropdown;
