import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, CardContent, Button, TextField, Divider, Typography, Grid, Tooltip, Box } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect, } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '../styles/project.css'
import { useLocation, useNavigate } from 'react-router-dom';
import jiratokenService from '../services/jiratoken.service';
import { fetchJiraTicket, FetchticketList, getJiraFetchEpicWiseTicket } from '../redux/actions/jira';
import { getProjects } from "../redux/actions/auth";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import '../styles/common.css';
import '../styles/custom.css';
import extractSortAndFilterParams from '../components/Table/filterSortParams';
import ServerPaginationGridNoRowCount from "../components/Table/DataGrid";
import showConfirmationAlert from "../components/Confirm";
import jiraService from '../services/jira.service';
import EditCalendarSharpIcon from '@mui/icons-material/EditCalendarSharp';
import { createJiraAITest, createJiraUATAITest, resetJiraAITest } from '../redux/actions/aiTest'
import { getJiraList } from '../redux/actions/jiraToken';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { IconButton } from "@mui/material";


const FetchJira = ({
    fetchJiraTicket, getJiraFetchEpicWiseTicket, DataList, createJiraAITest, createJiraUATAITest, getJiraList, jiraTokenList, AITest, resetJiraAITest, FetchticketList, jiraListEpicWise, datacount, getProjects, projectList
}) => {
    const navigate = useNavigate();
    const [TicketsList, setTicketsList] = useState([]);
    const [Base_url, setBase_url] = useState(null);
    const [ProjKey, setProjKey] = useState(null);
    const [Epicid, setEpicid] = useState(null);
    const [totalCount, setTotalCount] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [proj, setProj] = useState('Select');
    const [paginationModals, setPaginationModals] = useState({
        page: 0,
        pageSize: 100,
    });
    const [openRows, setOpenRows] = useState({});
    const Auth = JSON.parse(localStorage.getItem("authentication"));
    const [sortModels, setSortModels] = useState([]);
    const [filterModels, setFilterModels] = useState({ items: [] });

    const truncateText = (text, maxLength) => {
        if (text?.length <= maxLength) {
            return text;
        }
        return text?.substring(0, maxLength) + '...';
    };

    useEffect(() => {
        getProjects(0, 0, null, null, JSON.stringify({}))
        getJiraList(0, 0, null, null, JSON.stringify({}));
        getJiraFetchEpicWiseTicket();
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        FetchticketList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters));
    }, [getJiraList, paginationModals.page, paginationModals.pageSize, FetchticketList, getProjects]
    )

    const fetchData = () => {
        fetchJiraTicket().then((res) => {
            getJiraList(0, 0, null, null, JSON.stringify({}));
            getJiraFetchEpicWiseTicket();
            FetchticketList(0, 0, null, null, JSON.stringify({}));
        }).catch((err) => {
            console.error(err)
        })
    }
    const projects = projectList?.rows.map(({ proj_id, proj_name }) => ({
        id: proj_id,
        name: proj_name
    }));

    useEffect(() => {
        // const obj={
        //     case_id:AITest?.case_id
        // }
        if (AITest) {
            resetJiraAITest();
            navigate(`/testcase`, { state: AITest });
        }
    }, [AITest])

    useEffect(() => {
        if (Auth["proj_id"] && Auth["proj_id"] !== null) {
            setProj(Auth["proj_id"])
        }

    }, [Auth["proj_id"]])

    useEffect(() => {

        const obj = jiraTokenList?.filter(item => item.isdefault === true)
        console.log("obj", obj?.length);

        if (obj?.length <= 0) {
            showConfirmationAlert(
                'No Jira Token Added',  // Title
                'Click to add?',          // Text
                'Add Jira Token',       // Confirm button text
                'Cancel',                 // Cancel button text
                '#34B1AA',                // Confirm button color
                '#f44336c4'               // Cancel button color
            )
                .then((result) => {
                    if (result.isConfirmed) {
                        navigate('/JiraAccount')

                    } else if (result.isDismissed) {
                        setDisabled(true)
                    }
                });

        } else {
            obj?.forEach(item => {
                setBase_url(item.base_url);
                setProjKey(item.projectkey);
            });
            setDisabled(false)
        }

    }, [jiraTokenList]);


    useEffect(() => {
        const dataWithIds = DataList?.rows?.map((row, index) => ({
            ...row,
            id: row.id,
        }));
        setTicketsList(dataWithIds)
        setTotalCount(datacount)
        setExpandedRows(jiraListEpicWise)

    }, [DataList, datacount, jiraListEpicWise])
    const createTestCase = (Object) => {
        let user_story_title = []
        let user_story = []

        const child_records = expandedRows?.filter((row) => row.epic_id === Object.id);
        // if(child_records?.length>0){
        //     child_records.forEach(item => {

        //         if(item.summary?.length>0){
        //             user_story_title.push(item.summary);
        //         }else{
        //             user_story_title.push('');
        //         }
        //         if(item.description?.length>0){
        //             user_story.push(item.description);
        //         }else{
        //             user_story.push('');
        //         }
        //     });
        // }

        const obj = {
            Ids: Object.id,
            name: Object.summary,
            description: Object.description ? Object.description : '',
            epic_no: Object.epic_no,
            proj_id: proj
            // user_story:user_story,
            // user_story_title:user_story_title
        }
        if (proj==='' || proj ==="Select" || proj === 'undefined') {
            toast.error('Project cannot be empty');
            return;
        }
        createJiraAITest(obj)

    };
    const onChangeProject = (event) => {
        setProj(event.target.value);
    }
    const createUATTestCase = (Object) => {
        const obj = {
            Ids: Object.id,
            name: Object.summary,
            description: Object.description ? Object.description : '',
            proj_id: proj
        }
        createJiraUATAITest(obj)

    };
    const toggleRow = (id) => {
        setOpenRows((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const renderChildTable = (parentRowId) => {

        console.log(parentRowId)

        var parentRecord = expandedRows?.filter((row) => row.epic_id === parentRowId);

        if (openRows[parentRowId] && parentRecord) {
            console.log("CHILD RECORDS", parentRecord)
            return (
                <Table>
                    <TableHead>
                        <TableRow >
                            {/* <TableCell>Key</TableCell> */}
                            <TableCell >Ticket No</TableCell>
                            <TableCell >Summary</TableCell>
                            <TableCell >Type</TableCell>
                            <TableCell >Description</TableCell>
                            <TableCell >Assigne</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {parentRecord?.map((row) => (
                            <TableRow key={row.id}>
                                {/* <TableCell>{row.projectkey}</TableCell> */}
                                <TableCell>{row.ticket_no}</TableCell>
                                <Tooltip title={row.summary}>
                                    <TableCell>{truncateText(row.summary, 20)}</TableCell>
                                </Tooltip>
                                <TableCell>{row.issuetype}</TableCell>
                                <Tooltip title={row.description}>
                                    <TableCell>{truncateText(row.description, 20)}</TableCell>
                                </Tooltip>
                                <TableCell>{row.assigne}</TableCell>


                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            );
        }
        return null;
    };

    const tableRows = TicketsList?.map((row, index) => (
        <React.Fragment key={row.id}>
            <TableRow>

                <TableCell>{row.projectkey}</TableCell>
                <TableCell>{row.epic_no}</TableCell>
                <Tooltip title={row.summary}>
                    <TableCell>{truncateText(row.summary, 20)}</TableCell>
                </Tooltip>
                <TableCell>{row.issuetype}</TableCell>
                <Tooltip title={row.description}>
                    <TableCell>{truncateText(row.description, 20)}</TableCell>
                </Tooltip>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.istestcreated ? "True" : "False"}</TableCell>

                <TableCell>
                    {/* {row?.istestcreated === false ? (
                            <Tooltip title="Create UAT Test Case">
                                <AutoAwesomeMotionIcon
                                    sx={{ color: "#34B1AA" }}
                                    className="action-icon"
                                    onClick={() => createUATTestCase(row)}
                                />
                            </Tooltip>
                ) : null} */}
                    {row?.istestcreated === false ? (
                        <Tooltip title="Create Test Case">
                            <EditCalendarSharpIcon
                                sx={{ color: "#34B1AA" }}
                                className="action-icon"
                                onClick={() => createTestCase(row)}
                            />
                        </Tooltip>
                    ) : null}

                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleRow(row.id)}>
                        {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell colSpan={8}>
                    {renderChildTable(row.id)}
                </TableCell>
            </TableRow>

        </React.Fragment>
    ));


    return (
        <>
            <div>

                <Card raised>
                    <Typography variant="h5" sx={{ p: 2 }}>
                        Current Jira
                    </Typography>
                    <Divider />
                    
                    <CardContent>
                        <Grid container spacing={2} sx={{ p: 2 }}>
                            
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    Base Url:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <TextField value={Base_url} InputProps={{ readOnly: true }} />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    Project Key:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    <TextField value={ProjKey} InputProps={{ readOnly: true }} />
                                </Typography>
                            </Grid>


                        </Grid>

                        <div style={{ marginBottom: '30px' }}>
                            <Button sx={{ float: 'right' }} onClick={() => fetchData()} disabled={disabled}>Fetch Ticket</Button>
                        </div>
                    </CardContent>
                </Card>


                <br />
                <Box>
                    <Card
                    >
                        <Grid container direction="row" alignItems="center" spacing={3}  sx={{ p: 2 }}>
            {/* Jira Ticket List Title */}
            <Grid item>
                <Typography variant="h5">
                    Jira Ticket List
                </Typography>
            </Grid>

            {/* Project Dropdown */}
            <Grid item container spacing={1} alignItems="center" xs="auto">
                {/* <Grid item>
                    <Typography variant="subtitle2" fontSize={14}>
                        Project:
                    </Typography>
                </Grid> */}
                <Grid item>
                    <Select
                        style={{ height: '40px', minWidth: '250px' }}
                        onChange={onChangeProject}
                        value={proj}
                    >
                        <MenuItem value="Select" disabled>
                            Select Project
                        </MenuItem>
                        {projects?.map((row) => (
                            <MenuItem key={row.id} value={row.id}>
                                {row.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        </Grid>



                        <Divider></Divider>


                        <CardContent>


                            <TableContainer component={Card}>
                                <Table  >
                                    <TableHead >
                                        <TableRow className='th'>
                                            <TableCell >Key</TableCell>
                                            <TableCell >Epic No</TableCell>
                                            <TableCell >Summary</TableCell>
                                            <TableCell >Type</TableCell>
                                            <TableCell >Description</TableCell>
                                            <TableCell >Status</TableCell>
                                            <TableCell >Is TestCase Created</TableCell>

                                            <TableCell >Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableRows}
                                    </TableBody>
                                </Table>

                            </TableContainer>


                        </CardContent>

                    </Card>
                </Box>


            </div>
            <div>


            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    DataList: state.jira?.FetchJiraList,
    jiraTokenList: state.jiraToken?.JiraTokenList?.rows,
    jiraListEpicWise: state.jira?.JiraListEpicWise?.rows,
    datacount: state.jira?.FetchJiraList?.count,
    AITest: state.aiTest?.jiraAITest,
    projectList: state.auth.listProject


});

const mapDispatchToProps = {
    fetchJiraTicket,
    createJiraAITest,
    createJiraUATAITest,
    getJiraList,
    resetJiraAITest,
    FetchticketList,
    getJiraFetchEpicWiseTicket,
    getProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(FetchJira);


