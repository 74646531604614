import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import LandingPage from "../layouts/Header";
import Project from "../pages/Project";
import ProjectCases from "../pages/ProjectCases"
import { styled } from "@mui/material/styles";
import AddTestCases from "../pages/AddTestCases";
import Testcase from "../pages/Testcase";
import AllTestCases from "../pages/AllTestCases";
import RunJob from "../pages/RunJob";
import Suites from "../pages/Suites";
import AddCases from "../pages/SuitesPages/AddCases"
import TestCasesView from "../pages/SuitesPages/TestCasesView";
import AddTemplate from "../pages/Templates/AddTemplate";
import RunSuites from "../pages/SuitesPages/RunSuites";
import ExcelDownload from "../pages/Excel/ExcelDownload";
import TestCaseReport from "../pages/TestCaseReport";
import SuiteReport from "../pages/SuiteReport";
import Users from "../pages/Users";
import AddUser from "../pages/Add-User/AddUser";
import UserProfile from "../pages/Profile/UserProfile";
import Scheduler from "../pages/Scheduler/Scheduler";
import Crontab from "../pages/Scheduler/Crontab";
import ClockTask from "../pages/Scheduler/ClockTask";
import DashboardReport from "../pages/DaashboardReport";
import SuiteExecutionReport from "../pages/SuiteExecutionReport";
import FileUploader from "../pages/Files/file-uploader";
import AITest from "../pages/AI/AI-testScreen";
import StoredData from "../pages/Files/stored-data";
import DetailTestcaseReport from "../pages/Reports/DetailTestcaseReport";
import SchedularReport from "../pages/Reports/SchedularReport";
import DetailSuiteReport from "../pages/Reports/DetailSuiteReport";
import RunningTestModal from '../components/RunningTestModal';
import RunningSuitesModal from "../components/RunningSuitesModal";
import Task from "../pages/Task";
import CeleryRestart from "../pages/Celery";
import Jira from "../pages/Jira";
import JiraAccount from "../pages/JiraAccount";
import ProjectManagement from "../pages/ProjectManagement";
import UserTestCase from "../pages/UserTestCase"
import FetchJira from "../pages/JiraFetch"
import Plans from "../pages/Plans"
import OrgPlan from "../pages/OrgPlan"
import API from "../pages/API"

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
    },
  })
);
const PrivateRoute = ({ open }) => {
  const [ongoingOperation, setOngoingOperation] = useState(null);
  const [ongoingSuites, setOngoingSuites] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Main open={open}>

      <DrawerHeader />

      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/Landing" element={<LandingPage />} />
        <Route path="/project" element={<Project />} />
        <Route path="/test_cases" element={<ProjectCases />} />
        <Route path="/add-testcases" element={<AddTestCases />} />
        <Route path="/testcase" element={<Testcase />} />
        <Route path="/testcases/all" element={<AllTestCases />} />
        <Route path="/runJob" element={<RunJob ongoingOperation={ongoingOperation} setOngoingOperation={setOngoingOperation} />} />
        <Route path="/suites" element={<Suites />}></Route>
        <Route path="/add-cases" element={<AddCases />}></Route>
        <Route path="/view-cases" element={<TestCasesView />}></Route>
        <Route path="/add-template/:id" element={<AddTemplate />}></Route>
        <Route path="/run-suites/:id" element={<RunSuites ongoingSuites={ongoingSuites} setOngoingSuites={setOngoingSuites} />}></Route>
        <Route path="/excel-download" element={<ExcelDownload />}></Route>
        <Route path="/:sys_name" element={<TestCaseReport />} />
        <Route path="/SuiteReport" element={<SuiteReport />} />
        <Route path="/add-user" element={<AddUser />} />
        <Route path="/user" element={<Users />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/Scheduler" element={<Scheduler />} />
        <Route path="/crontab" element={<Crontab />} />
        <Route path="/clock-task" element={<ClockTask />} />
        <Route path="/DashboardReport" element={<DashboardReport />} />
        <Route path="/SuiteExecutionReport" element={<SuiteExecutionReport />} />
        <Route path="/files" element={<FileUploader />} />
        <Route path="/ai-test" element={<AITest />} />
        <Route path="/stored-data" element={<StoredData />} />
        <Route path="/detail-report" element={<DetailTestcaseReport />} />
        <Route path="/SuiteDetailReport" element={<DetailSuiteReport />} />
        <Route path="/SchedularReport" element={<SchedularReport />} />
        <Route path="/Task" element={<Task />} />
        <Route path="/CeleryRestart" element={<CeleryRestart />} />
        <Route path="/Jira" element={<Jira />} />
        <Route path="/JiraAccount" element={<JiraAccount />} />
        <Route path="/project_management" element={<ProjectManagement />} />
        <Route path="/assign_testcase" element={<UserTestCase />} />
        <Route path="/jiraFetch" element={<FetchJira />} />
        <Route path="/Plans" element={<Plans />} />
        <Route path="/OrgPlan" element={<OrgPlan />} />
        <Route path="/API" element={<API />} />

        <Route path="*" element={<Navigate to={`/dashboard`} replace />} />
      </Routes>
      {location.pathname !== '/runJob' &&
        <RunningTestModal ongoingOperation={ongoingOperation} setOngoingOperation={setOngoingOperation} isVisible={!!ongoingOperation} onClose={() => navigate('/runJob')}>
          {ongoingOperation}
        </RunningTestModal>
      }
      {location.pathname !== `/run-suites/${ongoingSuites ? JSON.parse(ongoingSuites).suiteId : ''}` &&
        <RunningSuitesModal ongoingSuites={ongoingSuites} setOngoingSuites={setOngoingSuites} isVisible={!!ongoingSuites} onClose={() => navigate(`/run-suites/${ongoingSuites ? JSON.parse(ongoingSuites).suiteId : ''}`, { state: ongoingSuites ? JSON.parse(ongoingSuites).state : '' })}>
          {ongoingSuites}
        </RunningSuitesModal>
      }
    </Main>
  );
};

export default PrivateRoute;
