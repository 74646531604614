import { connect } from "react-redux";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Link,
    Box,
    AppBar,
    Toolbar,
    Typography,
    TextField,
    Select,
    MenuItem,
    Container,
    Paper,
    Grid,
    CardContent,
    Accordion,
    AccordionSummary,
    AccordionDetails
  } from "@mui/material";
import { useState, useEffect } from "react";
import * as React from "react";
import AuthorizationDropdown from "../components/Api_auth"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const API = ({}) => {
  const [method, setMethod] = useState("GET");
  const [url, setUrl] = useState("");
  const [response, setResponse] = useState("Response will appear here");
  const [authorization, setAuthorization] = useState("");
  const [headers, setHeaders] = useState("");
  const [body, setBody] = useState("");
  const [selectedSection, setSelectedSection] = useState(null);
  
  const handleSendRequest = async () => {
    try {
      const res = await fetch(url, { method });
      const data = await res.json();
      setResponse(JSON.stringify(data, null, 2));
    } catch (error) {
      setResponse("Error: " + error.message);
    }
  };

  const setSection=(section)=>{
    if(section === "Authorization"){

    }
    setSelectedSection(section)

  }
  


  return (
    <Container>
      <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Select fullWidth value={method} onChange={(e) => setMethod(e.target.value)}>
              <MenuItem value="GET">GET</MenuItem>
              <MenuItem value="POST">POST</MenuItem>
              <MenuItem value="PUT">PUT</MenuItem>
              <MenuItem value="DELETE">DELETE</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={7}>
            <TextField fullWidth label="Enter URL" variant="outlined" value={url} onChange={(e) => setUrl(e.target.value)} />
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" color="primary" fullWidth onClick={handleSendRequest}>Send</Button>
          </Grid>
        </Grid>
      </Paper>


      <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
      <Grid container spacing={2} justifyContent="center">
        {["Authorization", "Headers", "Body"].map((section) => (
          <Grid item key={section}>
            <Button 
              variant={selectedSection === section ? "contained" : "outlined"} 
              onClick={() => setSection(section)}
            >
              {section}
            </Button>
          </Grid>
        ))}
      </Grid>

      {selectedSection && (
        <div style={{ marginTop: 20 }}>
          <Typography variant="h6">{selectedSection}</Typography>
          <div
            fullWidth
            label={selectedSection}
            variant="outlined"
            multiline
            rows={selectedSection === "Body" ? 4 : 2}
            value={
              selectedSection === "Authorization"
                ? authorization
                : selectedSection === "Headers"
                ? headers :body
                
            }
            onChange={(e) => {
              const value = e.target.value;
              if (selectedSection === "Authorization") setAuthorization(value);
              else if (selectedSection === "Headers") setHeaders(value);
              else if (selectedSection === "Body") setBody(value);
    
            }}
            

            sx={{ mt: 2 }}
          >
          {selectedSection === "Authorization"? 
         
            
            <AuthorizationDropdown/>
            
          : "" }

          </div>
                        

        </div>
      )}
    </Paper>

      <Paper elevation={3} style={{ padding: 20, marginTop: 20, minHeight: 200, backgroundColor: "#f5f5f5" }}>
        <Typography variant="h6">Response</Typography>
        <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{response}</pre>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(API);