export const VALIDATION_CHOICES = [
    ['text() =', 'text() ='],
    ['contains()', 'contains()'],
    ['=','='],
    ['!=','!='],
    ['<','<'],
    ['>','>'],
    // ['and','and'],
    // ['or','or'],
  
];

export default VALIDATION_CHOICES;