import { Button, Select, MenuItem, Card, Modal, Typography, CardContent } from "@mui/material";
import { connect } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { runJob } from "../redux/actions/runJob";
import { StopJob } from "../redux/actions/stop";
import { modalPopUpStyleLarge } from '../styles/getLPTheme';
import { useLocation, UseState, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import "../styles/project.css";
import io from "socket.io-client";
import ENV_VAR from "../common";
import OngoingOperation from "../components/OngoingOperation";
import { getNotificationList } from "../redux/actions/notification";
import { getEnviromentList } from "../redux/actions/enviroment"
import { Env_Persona } from "../redux/actions/environmentPersona";
import Tooltip from '@mui/material/Tooltip';
import {isFeaturePresent,getFeatureValue} from '../middleware/setAuthToken.js'
import { getTestJobs } from "../redux/actions/testCaseReport";
import { getSuiteJobs } from "../redux/actions/suiteReport";






const SOCKET_SERVER_URL = `${ENV_VAR.API_HOST}`;

export const RunJob = ({ getEnviromentList, listOfEnviroment, runJob, StopJob, ongoingOperation, setOngoingOperation, NotificationList, getNotificationList, getPersonaList, ListData,Env_Persona,run_tc_count,run_stc_count,getTestJobs,getSuiteJobs }) => {
  const socketRef = useRef(null);
  const { state } = useLocation();
  const location = useLocation();
  const [testData, setTestData] = useState("");
  const [progress, setProgress] = useState(0);
  let [items, setItems] = useState("");
  const [taskId, setTaskId] = useState(null);
  const [caseId, setCaseId] = useState(null);
  const [Stoptask, setStoptask] = useState('');
  const [Image, setImage] = useState(null);
  const [Message, setMessage] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [dropdownList, setDropdownList] = useState([]);
  const [Personadropdown, setPersonadropdown] = useState([]);
  const [selectedValue, setSelectedValue] = useState("select");
  const [selectedPersona, setSelectedPersona] = useState("select");
  const [EnvId, setEnvId] = useState(null);
  const [progressBarColor, setProgressBarColor] = useState("ADD8E6")
  const [Value, setValue] = useState(null);
  const [RunningJob, setRunningJob] = useState(null);
  const [Error, setError] = useState('');
  
  const handleClose = () => {
    setOpen(false);
    setIsVisible(true);
  };
  const updatePopUp = () => {
    setOpen(true);
    setIsVisible(false);
  }

  useEffect(()=>{
    getTestJobs();
    getSuiteJobs();
  },[])

  console.log("TESTDATA", testData)
  const updateDropdownList = () => {
    if (listOfEnviroment?.length > 0) {
      const values = listOfEnviroment
        .filter(item => item.proj_id === testData?.proj_id && item.is_active === true)
        .map(item => ({
          value: item.env_id,
          label: item.name,
        }));

      setDropdownList(values);
    }
  };

  const updatePersonaDropdownList = () => {
    console.log(selectedValue)
    if (ListData?.length > 0) {
      const values = ListData.filter(item=> item.env_id === selectedValue)
        .map(item => ({
          value: item.persona_id,
          label: item.name,
        }));

      setPersonadropdown(values);
    }
  };

  useEffect(()=>{
    if(EnvId!==null){
      Env_Persona(EnvId)
        .then((res) => {
         
          console.log("obj",res); // Logs the result of the successful response
          
        })
        .catch((err) => {
          
          console.error(err); // Logs the error for debugging
        });

          }
    
  },[selectedValue])


  console.log("ID", selectedValue)
  useEffect(() => {
    updateDropdownList();

  }, [listOfEnviroment, testData]);

  useEffect(() => {
    updatePersonaDropdownList();

  }, [ListData, selectedValue]);

  const handleChange = (event) => {
    console.log("handel chnage", event)
    if(event.target.value !=='select' && event.target.value !== undefined){
      setEnvId(event.target.value)
    }
    setSelectedValue(event.target.value);
    // You can also handle additional logic here when the dropdown value changes
  };
  const handlePersonaChange = (event) => {
    console.log("handel chnage", event)
    setSelectedPersona(event.target.value);
    // You can also handle additional logic here when the dropdown value changes
  };


  useEffect(()=>{
    if(isFeaturePresent('Parallel Execution')){
      const val = getFeatureValue('Parallel Execution');
      if (val){
        setValue(parseInt(val, 10));
      }
    }
  },[])

  

  useEffect(() => {
    // Initialize socket connection
    socketRef.current = io(SOCKET_SERVER_URL, {
      withCredentials: true,
    });

    const handleImgToReact = (data) => {
      setIsVisible(true);
      if (taskId === data.data[1]) {
        setImage("data:image/png;base64, " + data.data[0]);
      }
    };


    const handleMessageToReact = (data) => {
      if (caseId === data.data[2] && taskId === data.data[3]) {
        setStoptask(data.data[3]);
        setProgress(data.data[0]);
        getText(data.data[1]);
        setItems(data.data[1]);
        if (data.data[0] === 100) {
          socketRef.current.off("message_to_react", handleMessageToReact);
          socketRef.current.off("img_to_react", handleImgToReact);
          socketRef.current.disconnect();
          setIsDisabled(false);
          setOpen(false);
          setIsVisible(false);
          setOngoingOperation(null);
          setProgressBarColor("#4CBB17");

        }


      }
    };

    // Set up event listeners
    socketRef.current.on("img_to_react", handleImgToReact);
    socketRef.current.on("message_to_react", handleMessageToReact);

    // Clean up on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.off("img_to_react", handleImgToReact);
        socketRef.current.off("message_to_react", handleMessageToReact);
        socketRef.current.disconnect();
      }
    };
  }, [taskId, caseId, SOCKET_SERVER_URL]);




  useEffect(() => {
    if (state) {
      setTestData(state);
    }
    getEnviromentList(0, 0, null, null, JSON.stringify({}));
  }, [state]);

  const navigateToTestCase = () => {
    let obj = {
      case_id: testData.case_id,
    };
    navigate(`/testcase`, { state: obj });
  };

  

  const parseLogString = (items) => {
    const lines = items.split("</br>");
    return lines.map((line, index) => (
      <li key={index} dangerouslySetInnerHTML={{ __html: line }} />
    ));
  };

  const getText = (items) => {
    const cleanedItems = items.replace(/running Step :/g, 'Step :');
    const lines = cleanedItems.split('</li>');
    const lastItem = lines[lines.length - 2];
    const startIndex = lastItem?.lastIndexOf('>') + 1;
    const liText = lastItem?.substring(startIndex)?.trim();
    setMessage(liText)
  };
  const startJobApi = () => {
    setOngoingOperation(null);
    if (socketRef.current) {
      socketRef.current.connect();
    };


    setIsDisabled(true);
    setImage(null);
    let obj = {
      test_id: testData?.case_id,
      env_id: selectedValue.length > 0 ? selectedValue : null,
      persona: selectedPersona.length > 0 && selectedPersona !== 'select' ? selectedPersona : null
    }
    runJob(obj).then((res) => {
      setTaskId(res.taskId)
      // callApiAfterDelay();
    });

    setCaseId(testData?.case_id);
    setProgress(0);
    setItems("");
    setStoptask('');
  };


  useEffect(() => {
    if (taskId && caseId) {
      if (progress === 100) {
        setIsDisabled(false);
        setOngoingOperation(null);
        // callApiAfterDelay();
      } else {
        setIsDisabled(true);
        setOngoingOperation(JSON.stringify({ taskId: taskId, caseId: caseId, progress: progress, items: items, testData: testData, Image: Image, Stoptask: Stoptask }));
      }
    }
  }, [taskId, caseId, progress, items, testData, Image]);

  useEffect(() => {
    if (ongoingOperation) {
      let opr = JSON.parse(ongoingOperation);
      setTaskId(opr?.taskId);
      setCaseId(opr?.caseId);
      setProgress(opr?.progress);
      setItems(opr?.items);
      setTestData(opr?.testData);
      setImage(opr?.Image);
      setStoptask(opr?.Stoptask);
    }
  }, [ongoingOperation]);

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return text?.substring(0, maxLength) + '...';
  };

  const stopJobApi = () => {
    StopJob(Stoptask, "TestCase");
  };

  useEffect(()=>{
    let count=0
    if(run_stc_count){
      const obj= run_stc_count?.filter(item => item.status === "running").length || 0
      count=count+obj
    }
    if(run_tc_count){
      const obj= run_tc_count?.filter(item => item.status === "running").length || 0
      count =count+obj
    }
    setRunningJob(count)
  },[run_stc_count,run_tc_count])



  useEffect(() => {
    if (Value === RunningJob) {
      setIsDisabled(true)
      setError("Cannot run more jobs. Maximum limit reached.");
    } else {
      setIsDisabled(false)
      setError(""); // Clear error if condition is not met
    }
  }, [Value, RunningJob]);

  console.log(RunningJob,Value)
  // const progressBarColor = progress === 100 ? "#4CBB17" : "#ADD8E6";

  return (
    <div className="marginTop">
      <Card className="padding-runJob marginTop">
        <h3 className="label-bold" style={{ cursor: 'pointer' }}>
          <Tooltip className="label-bold" title={testData.case_name} >
            Test case name: {truncateText(testData.case_name, 60)}
          </Tooltip>
        </h3>

        {/* <h3 className="label-bold">Test case name: {testData.case_name}</h3> */}
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <CardContent style={{ marginRight: '10px' }}>
            <Box>
              <Button onClick={startJobApi} disabled={isDisabled}  >Start Job</Button>
              <Button onClick={navigateToTestCase} colorStyle="cancel">
                Back
              </Button>
              {Error && ( 
                <Typography color="error" sx={{ mt: 1 }}>
                  {Error}
                </Typography>
              )}


            </Box>
            <br />
            {Stoptask && progress <= 90 ? <Box>
              <Button onClick={stopJobApi} colorStyle="cancel">Stop Job</Button>
            </Box> : null}
            <br />
            {isFeaturePresent('Environment') && 
              <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '300px' }}>
                {dropdownList?.length>0 && (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <label style={{ fontWeight: 'bold', marginBottom: '4px' }} className='marginRight'>
                      Select Environment
                    </label>
                    <Select onChange={handleChange} value={selectedValue}>
                      <MenuItem value="select" key="">Select Environment</MenuItem>
                      {dropdownList?.map((item, index) => (
                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  </div>
                )}

                {Personadropdown?.length > 0 && selectedValue !== '' && selectedValue !== 'select' && (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <label style={{ fontWeight: 'bold', marginBottom: '4px' }} className='marginRight'>
                      Select Persona
                    </label>
                    <Select onChange={handlePersonaChange} value={selectedPersona}>
                      <MenuItem value="select" key="">Select Persona</MenuItem>
                      {Personadropdown?.map((item, index) => (
                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            }
          </CardContent>
          {isFeaturePresent('Live Streaming') &&    
            <CardContent>
              {isVisible && Image && (
                <div style={{ right: 0, border: '2px solid #ced4da', cursor: 'pointer' }}>
                  <div onClick={updatePopUp}>
                    <img src={Image} alt="screenshot" style={{ height: '200px', width: '400px', padding: '10px' }} />
                  </div>
                </div>
              )}
            </CardContent>
          }
        </div>

        <div className="d-flex flex-column mb-1 max-width-progress-wrap">
          <p className="text-success">
            {taskId !== null && taskId !== undefined ? (
              `${progress}%`
            ) : (
              <span>Waiting for progress to start...</span>
            )}
          </p>

          {taskId !== null && taskId !== undefined ? (
            <div
              className="progress"
              role="progressbar"
              style={{ height: "30px", width: "100%" }} // Ensure progress bar container has width
              aria-label="Progress example"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                className="progress-bar progress-bar-striped"
                style={{
                  width: `${progress}%`,
                  backgroundColor: progressBarColor || 'green', // fallback to green if not provided
                  height: "100%",
                  transition: "width 0.5s ease-in-out",
                }}
              ></div>
            </div>
          ) : (
            ""
          )}
        </div>
        {taskId !== null && taskId !== undefined ? (
          <div>
            <ul className="bullet-line-list">{parseLogString(items)}</ul>
            <div></div>
          </div>
        ) : (
          ""
        )}
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
          sx={{
            ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete="off">

          <div className="modal-header">
            <img src={Image} alt="Screenshot" style={{ maxWidth: '100%', height: '100%' }} />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">{Message}</Typography>
            <Typography gutterBottom textAlign="right" variant="h5">
              <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  validationList: state.auth.validationsList,
  runJob: state.auth.runJobStatus,
  StopJob: state.auth.stopJob,
  NotificationList: state.notification?.notification,
  listOfEnviroment: state?.Enviroment?.EnviromentList?.rows,
  ListData: state.Enviroment_Persona?.Env_personaList?.rows,
  run_tc_count :state.testCaseReport?.testJobList,
  run_stc_count: state.suiteReport?.SuiteJobList,
});

const mapDispatchToProps = {
  runJob,
  StopJob,
  getNotificationList,
  getEnviromentList,
  Env_Persona,
  getTestJobs,
  getSuiteJobs
};

export default connect(mapStateToProps, mapDispatchToProps)(RunJob);
